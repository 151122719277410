import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { getJwt } from '../../../helpers/jwt';
import { withRouter } from 'react-router-dom';

class Auth extends Component {
    
    componentDidMount() {
        const jwt = getJwt();
        if (!jwt) {
            this.props.history.push(this.props.locale + '/unauthorized');
        }
    }
    render() {
        return <div></div>
    }
}

Auth.defaultProps = {
    locale: 'en'
}

Auth.ropTypes = {
    locale: PropTypes.string
}

export default withRouter(Auth);