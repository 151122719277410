import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Footer from '../partials/Footer'

class Unauthorized extends Component {
    componentDidMount() {
        this.props.setLocale(this.props.locale)
    }
    render() {
        return (
            <>
                <div className="unauthorized">
                    <div className="container">
                        { this.props.t('unauthorized.message') }
                    </div>
                </div>
                <Footer t={this.props.t} />
            </>
        )
    }
}

export default withTranslation()(Unauthorized)