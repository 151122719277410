import  React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import './resources/sass/App.scss';
import Auth from './resources/views/components/Auth';
import Data from './Data'
import Unauthorized from './resources/views/pages/Unauthorized';
import ReactGA from 'react-ga'

const trackingID = 'UA-160167278-1'
ReactGA.initialize(trackingID)

const Header = () => 
	<header>
		<div>
			<a href='/'>
				<div>
					<img src={require('./resources/img/IDEA_logo.png')} alt='IDEA logo' />
				</div>
			</a>
		</div>
	</header>

const App = () => {
	const { i18n } = useTranslation();
	const defaultLang = i18n.options.fallbackLng[0];
	const setLocale = lng => i18n.changeLanguage(lng)

	return (
		<>
			<Header/>
			<main>
				<Router>
					<Switch>
						<Route 
							path="/unauthorized" 
							render={() => 
								<>					
									<Unauthorized 
										locale={defaultLang}
										setLocale={setLocale}
									/>
								</>
							}
						/>
						<Route 
							path="/:locale/unauthorized" 
							render={routeProps => 
								<>					
									<Unauthorized 
										locale={routeProps.match.params.locale}
										setLocale={setLocale}
									/>
								</>
							}
						/>
						<Route 
							path="/module/:slug"
							exact
							render={routeProps => 
								<>					
									<Auth locale="" />
									<Data 
										locale={defaultLang} 
										page="ModuleDetails" 
										slug={routeProps.match.params.slug} 
										setLocale={setLocale}
									/>
								</>
							}
						/>
						<Route 
							path="/:locale/module/:slug"
							exact
							render={routeProps =>		
								<>	
									<Auth locale={"/" + routeProps.match.params.locale} />
									<Data 
										locale={routeProps.match.params.locale} 
										page="ModuleDetails" 
										slug={routeProps.match.params.slug} 
										setLocale={setLocale}
									/>
								</>
							}
						/> 
						<Route 
							path="/:locale" 
							exact
							render={routeProps => 
								<>
									<Auth locale={"/" + routeProps.match.params.locale}  />
									<Data 
										locale={routeProps.match.params.locale} 
										page="ModuleList" 
										setLocale={setLocale}
									/>
								</>
							}
						/>
						<Route 
							path="/" 
							render={() => 
								<>					
									<Auth locale="" />
									<Data 
										locale={defaultLang} 
										page="ModuleList" 
										setLocale={setLocale}
									/>
								</>
							}
						/>
					</Switch> 
				</Router>
			</main>
		</>
	)
}


export default App