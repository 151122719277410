import React  from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

const PageTitle = ({ title, backLink, locale }) => {
	return (
        <div className="page-title">
            <div className="container">
                {backLink && (
                    <div className="page-title__back-link">
                        <Link to={'/' + (locale !== 'en' ? locale : '')}>
                            <img src={require('../../img/arrow.png')} alt="back" />
                        </Link>
                    </div>
                )}
                <h1 className={backLink ? 'with-link' : ''}>{title}</h1>
            </div>
        </div>
	)
}

PageTitle.defaultProps = {
    title: '',
    backLink: false,
    locale: 'en'
}

PageTitle.propTypes = {
    title: PropTypes.string,
    backLink: PropTypes.bool,
    locale: PropTypes.string
}

export default PageTitle
