import React from 'react';
import PageTitle from '../partials/PageTitle'

const Error404 = () => {
    return (
        <>
            <PageTitle title="Page not found" />
            <section className="error"></section>
        </>
    )
}

export default Error404