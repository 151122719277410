import React from 'react';
import { withTranslation } from "react-i18next";
import moment from 'moment';

const Footer = ({ t }) => 
	<footer>
		<div>
            <span dangerouslySetInnerHTML={{__html: t('general.copyright', { year: moment().year() }) }} />
		</div>
	</footer>

export default withTranslation()(Footer)