import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const Dropdown = ({ onChange, options, isSearchable }) => {
	
	let mobileItems = []
	options.forEach((option, key) => {
		mobileItems.push(<option key={key} value={option.value}>{option.label}</option>)
	})

	return (
		<>
			<Select
				className="dropdown dropdown--desktop"
				classNamePrefix="select"
				defaultValue={options[0]}
				isSearchable={isSearchable}
				onChange={onChange}
				options={options}
			/>
			<select onChange={onChange} className="dropdown dropdown--mobile">
				{mobileItems}
			</select>
		</>
	)
}

Dropdown.defaultProps = {
    isSearchable: false
}

Dropdown.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.array,
    isSearchable: PropTypes.bool
}

export default Dropdown