import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import Rating from 'react-rating';
import moment from 'moment';
import { withCookies, Cookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

class CommentForm extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            isFormSubmitting: false,
            isFormSuccess: false,
            isFormFail: false,
            formErrorMessage: null,
            textAreaValue: '',
            value: 0,
        }
    }
    
    componentDidMount() {
        fetch(process.env.REACT_APP_API + `modules/wp-id/${this.props.moduleId}/rating`)
            .then(res => res.json())
            .then(value => this.setState({
                value
            }));
    }

    handleSubmit(e) {
        e.preventDefault();
        const [postId, name, email, comment] = e.target.elements;
        const sendData = JSON.stringify({
            post: postId.value,
            author_name: name.value,
            author_email: email.value,
            content: comment.value,
        });

        fetch(this.props.wpJson + `/comments?`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: sendData,
        })
        .then((response) => {
            if (response.ok === true) {
                this.setState({
                    isFormSubmitting: false,
                    isFormSuccess: true,
                    textAreaValue: '',
                });
            }
            return response.json();
        })
        .then((object) => {
            this.setState({
                isFormSubmitting: false,
                isFormFail: true,
                formErrorMessage: object.message,
            });
        })
        .catch(error => console.error('Error:', this.state.formErrorMessage));
    }

    handleRating(value) {
        if (!this.props.cookies.get(`rated-${this.props.moduleId}`)) {
            this.props.cookies.set(`rated-${this.props.moduleId}`, value, { expires: new Date(moment().add(1, 'y').format()), path: '/' });
            fetch(process.env.REACT_APP_API + `modules/wp-id-create/${this.props.moduleId}`, {
                method: "POST",
                headers: {
                Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    score: value
                })
            }).then(res => res.json());
        }
    }

    render() {
        const {t, cookies} = this.props
        return (
            <div className="comment-form">
                <div className="container">
                    <h2>{this.props.t('comments.header')}</h2>
                    { this.state.isFormSuccess ? <p className="comment-form__success">{t('comments.success')}</p> : null }
                    { this.state.isFormFail && this.state.isFormSuccess === false ? <p className="comment-form__error">{t('comments.error')}<br/>{this.state.formErrorMessage} </p> : null}
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <input 
                            id="postId" 
                            type="hidden" 
                            value={this.props.moduleId} 
                        /> 
                        <div className="two-col">
                            <div>
                                {/* <label htmlFor="name">{t('comments.name')}*</label> */}
                                <input 
                                    id="name" 
                                    type="text" 
                                    required 
                                    disabled={this.state.isFormSubmitting} 
                                    placeholder={t('comments.name') + ' *'}
                                />
                            </div>
                            <div>
                                {/* <label htmlFor="email">{t('comments.email')}*</label> */}
                                <input
                                    id="email"
                                    type="email"
                                    required
                                    disabled={this.state.isFormSubmitting}
                                    placeholder={t('comments.email') + ' *'}
                                />
                            </div>
                        </div>
                        <div>
                            {/* <label htmlFor="comment">{t('comments.message')}*</label> */}
                            <textarea
                                id="comment"
                                rows="10"
                                required
                                disabled={this.state.isFormSubmitting}
                                onChange={e => {
                                    this.setState({ textAreaValue: e.target.value });
                                }}
                                value={this.state.textAreaValue}
                                placeholder={t('comments.message') + ' *'}
                            />
                        </div>
                        {cookies.get(`rated-${this.props.moduleId}`) ? (
                            <Rating
                                id={this.props.moduleId} 
                                emptySymbol={<FontAwesomeIcon icon={faStar} className="star star--empty" />}
                                fullSymbol={<FontAwesomeIcon icon={faStar} className="star star--personal" />}
                                initialRating={cookies.get(`rated-${this.props.moduleId}`)}
                                readonly
                                quiet
                            />  
                        ) : (
                            <Rating
                                id={this.props.moduleId} 
                                placeholderRating={this.state.value.value}
                                emptySymbol={<FontAwesomeIcon icon={faStar} className="star star--empty" />}
                                placeholderSymbol={<FontAwesomeIcon icon={faStar} className="star star--full" />}
                                fullSymbol={<FontAwesomeIcon icon={faStar} className="star star--personal" />}
                                fractions={1}
                                onChange={this.handleRating.bind(this)}     
                            />  
                        )}
                        <div>
                            {this.state.isFormSubmitting ? <input type="submit" value="Submitting" className="btn" disabled /> : <input type="submit" value={t('general.submit')} className="btn" />}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default withCookies(CommentForm);