import  React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Unauthorized from './resources/views/pages/Unauthorized';
import ScrollToTop from './resources/views/components/ScrollToTop';
import ModuleList from './resources/views/pages/ModuleList';
import ModuleDetails from './resources/views/pages/ModuleDetails';
import Error404 from './resources/views/pages/404'
import Loader from './resources/views/components/Loader';

class Data extends Component {

    constructor(props){
        super(props);
        this.state = {
			data: [],
			error: '',
			isLoading: true,
			locale: this.props.locale === 'en' ? '' : this.props.locale.toLowerCase(),
			redirectToDefault: false,
			redirectTo404: false
		}
		this.wpJson = process.env.REACT_APP_WP  + this.state.locale + '/wp-json/wp/v2'
	}

	async componentDidMount() {
		const fetchModules = fetch(this.wpJson + '/modules?per_page=100').then(res => (res.json()))
		const fetchUsers = fetch(this.wpJson + '/users').then(res => (res.json()))
		const fetchPages = fetch(this.wpJson + '/pages').then(res => (res.json()))
		const fetchCategories = fetch(this.wpJson + '/categories').then(res => (res.json()))
		const fetchComments = fetch(this.wpJson + '/comments').then(res => (res.json()))

		this.props.setLocale(this.props.locale)
		
		Promise.all([ 
			fetchModules, 
			fetchUsers, 
			fetchPages, 
			fetchCategories,
			fetchComments
		 ]).then(responses => {
			let categoryList = []
			responses[3].map(category => {
				categoryList.push(category.id)
			})
			this.setState({
				isLoading: false,
				data: responses,
				categoryList
			})
		}).catch(error => {
			if (this.props.locale === 'en') {
				this.setState({
					isLoading: false,
					redirectToDefault: false,
					redirectTo404: true,
					error
				})
			} else {
				this.setState({
					isLoading: false,
					redirectToDefault: true
				})
			}
		})
	}
	
	returnPage() {

		if (this.state.redirectTo404) {
			return <Error404 />
		} else {
			if (this.state.redirectToDefault) {
				window.location.href = '/en'
			} else {
				if (!this.state.isError) {
					switch(this.props.page) {
						case 'Unauthorized':
						default:
							return <Unauthorized t={this.props.t} />
						case 'ModuleList':
							return ( 
								<ScrollToTop>   
									<ModuleList 
										categories={this.state.data[3]}
										categoryList={this.state.categoryList}
										locale={this.props.locale}
										modules={this.state.data[0]} 
										modulesPerPage={6}
										pages={this.state.data[2]}
										urlLocale={this.state.locale}
										users={this.state.data[1]}
										t={this.props.t}
									/>
								</ScrollToTop>
							)
						case 'ModuleDetails':
							return (
								<ScrollToTop>
									<ModuleDetails 
										comments={this.state.data[4]}
										locale={this.props.locale}
										modules={this.state.data[0]} 
										slug={this.props.slug}
										urlLocale={this.state.locale}
										users={this.state.data[1]}
										wpJson={this.wpJson}
										t={this.props.t}
									/>
								</ScrollToTop>
							)
					}
				}
			} 
		}
	}

    render() {	
		return ( 
			<div className="idealab">
				{ this.state.isLoading ? <Loader /> : this.returnPage() }
			</div>
		)
    }
}

Data.propTypes = {
	locale: PropTypes.string.isRequired,
	page: PropTypes.string.isRequired,
	slug: PropTypes.string
}

export default withRouter(Data)