import React, { Component }  from 'react';
import Slider from 'react-slick';
import Lightbox from 'react-image-lightbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpand } from '@fortawesome/free-solid-svg-icons'

class Gallery extends Component {
    constructor(props){
        super(props);
        this.state = {
          current: ''
        }
    }

    handleImageExpand = (e, image) => {
        e && e.preventDefault();
        this.setState({ current: image })
    }

    handleModalClose = e => {
        e && e.preventDefault();
        this.setState({ current: '' })
    }

    render() {
        let settings = {
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500
        };

        return (
            <>
                <Slider {...settings}>
                    {this.props.images.map(image => (
                        <div key={image.id}>
                            <div onClick={(e) => this.handleImageExpand(e, image)} className="expand-container">
                                <FontAwesomeIcon icon={faExpand} className="expand-icon"/>
                            </div>
                            <img src={image.url} alt={image.alt} />
                        </div>
                    ))}
                </Slider>
                {this.state.current && 
                    <Lightbox 
                        mainSrc={this.state.current.url} 
                        onCloseRequest={this.handleModalClose}
                        imageCaption={this.state.current.caption} 
                    />
                }
            </>
        );
    }
}

export default Gallery;