import React  from 'react';
import PageTitle from '../partials/PageTitle'

const loaderStyle={
    textAlign: 'center',
    padding: '100px'
}
const imgStyle = {
    width: '40px',
    height: 'auto'
}

const Loader = () => {
	return (
        <>
            <PageTitle title="" />
            <section className="loading" style={loaderStyle}>
                <img 
                    src={require('../../img/loading.gif')} 
                    style={imgStyle}
                    alt='loading'
                />
            </section>
        </>
	)
}

export default Loader
