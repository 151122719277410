import React, { Component }  from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import ReactGA from 'react-ga'
import 'moment/locale/fr';
import 'moment/locale/es';
import PageTitle from '../partials/PageTitle'
import Checkbox from '../partials/Checkbox'
import Dropdown from '../partials/Dropdown'
import Footer from '../partials/Footer'
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTag, faTags, faStar } from '@fortawesome/free-solid-svg-icons'

const ModuleBlock = props => {
    let categoryList = []
    props.allCategories.forEach(category => {
        if (props.moduleCategories.includes(category.id)) {
            categoryList.push(category.name)
        }
    })

    const onDownloadClick = () => {
        ReactGA.event({
            action: 'Downloads',
            category: props.title,
            label: 'Module Overview page'
        });
    }

    return (
        <div className="module-block">
            <Link to={{ pathname: `${props.urlLocale}/module/${props.slug}` }}>
                <div className="module-block__icon">
                        { props.thumbnail && (
                            <img src={ props.thumbnail.url } alt={ props.thumbnail.alt }/>
                        )}
                        <div className="module-block__title module-block__title--mobile">
                            <div>
                                <h3 dangerouslySetInnerHTML={{__html: props.title }} />
                                {props.verified && (<img src={require('../../img/verified.png')} alt='verified' className="verified" />)}
                            </div>
                        </div>
                </div>
            </Link>
            <div className="module-block__content">
                <Link to={{ pathname: `${props.urlLocale}/module/${props.slug}` }}>
                    <div className="module-block__title">
                        <div>
                            <h3 dangerouslySetInnerHTML={{__html: props.title }} />
                            {props.verified && (<img src={require('../../img/verified.png')} alt='verified' className="verified" />)}
                        </div>
                    </div>
                    <div className="description">
                        <div className="description__top">
                            <p>{ props.excerpt }</p>
                        </div>
                        <div className="description__bottom">
                            <div>
                                {props.ratings[props.id] && (
                                    <div className="rating-container">
                                        <Rating
                                            id={props.id} 
                                            initialRating={props.ratings[props.id]}
                                            emptySymbol={<FontAwesomeIcon icon={faStar} className="star star--empty" title={Math.round(props.ratings[props.id] * 10) / 10 + '/5'} />}
                                            fullSymbol={<FontAwesomeIcon icon={faStar} className="star star--full" title={Math.round(props.ratings[props.id] * 10) / 10 + '/5'} />}
                                            readonly
                                            quiet
                                        />
                                    </div>
                                )}
                                <span><b>{ props.author }</b>&nbsp;&nbsp;{ props.locale == 'de' ? moment(props.date).format("D.M.Y") : moment(props.date).locale(props.locale).format("MMM Do, YYYY") }</span>
                                { categoryList.length > 0 && (
                                <div className="module-block__categories">
                                    {categoryList.length > 1 ? <FontAwesomeIcon icon={faTags}/> : <FontAwesomeIcon icon={faTag}/>}
                                    <span>{ categoryList.join(', ') }</span>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div>
                { props.download && (
                    <>
                        <div className="download">
                            <a 
                                href={ props.download } 
                                download
                                onClick={() => onDownloadClick()}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn" 
                            >
                                <div>{props.t('general.download')}</div>
                            </a>
                        </div>
                        <div className="download">
                            <Link to={{ pathname: `${props.urlLocale}/module/${props.slug}` }} className="btn btn--2" >
                                <div>{props.t('general.learn-more')}</div>
                            </Link>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

class ModuleList extends Component {
    constructor(props){
        super(props);
        this.state = {
            allCategoriesChecked: true,
            checkedCategories: this.props.categoryList,
            modules: this.props.modules,
            pageNumber: 0,
            paginatedModules: [],
            ratings: {},
            searchValue: ''
        }
        this.updateSelectedCategories = this.updateSelectedCategories.bind(this);
        this.overviewContent = this.props.pages.find(page => page.slug === 'overview');
    }

    
    componentDidMount() {

        this.fetchAndLog();
        this.props.modules.map(module => {
            fetch(process.env.REACT_APP_API + `modules/wp-id/${module.id}/rating`)
            .then(res => res.json())
            .then(value => {
                this.setState(prevState => ({
                    ratings: {...prevState.ratings, [module.id]: value.value}
                }))
            })
            .catch(error => {
                console.log("error:", error)
            })
        })

        this.paginateModules();

    }

    async fetchAndLog() {
        const response = await fetch(process.env.REACT_APP_API + `modules/`);
        const json = await response.json();
    }

    sortModules = selected => {
        let modules = [];
        modules = [...this.state.modules].sort((a, b) => {
            let dateA = a.acf.date_published ? moment(a.acf.date_published, 'DD/MM/YYYY') : moment(a.date);
            let dateB = b.acf.date_published ? moment(b.acf.date_published, 'DD/MM/YYYY') : moment(b.date);
            switch(selected.value) {
                case 'recent':
                default: 
                    return dateB - dateA;
                case 'title-asc':
                    return a.title.rendered.localeCompare(b.title.rendered);
                case 'title-desc':
                    return b.title.rendered.localeCompare(a.title.rendered);
            }
        });
        this.setState({ modules }, () => {
            this.paginateModules();
        });
    }

    selectAllCategories = () => {
        this.setState(prevState => ({
            checkedCategories: prevState.allCategoriesChecked ? [] : this.props.categoryList,
            allCategoriesChecked: !prevState.allCategoriesChecked
        }), () => this.filterModules())
    }

    updateSelectedCategories = categoryId => {
        this.setState(prevState => ({ 
            checkedCategories: prevState.checkedCategories.includes(categoryId) ? prevState.checkedCategories.filter(category => category !== categoryId) : [...prevState.checkedCategories, categoryId],
            allCategoriesChecked: this.state.checkedCategories.length !== this.props.categoryList ? false : prevState.allCategoriesChecked
        }), () => this.filterModules())
    }

    filterModules = () => {
        let filteredModules = this.props.modules.filter(module => {
            let matchingCategories = this.state.allCategoriesChecked ? true : module.categories.some(r => this.state.checkedCategories.includes(r))
            let searchableContent = "";
            searchableContent = module.slug + module.title.rendered + module.acf.file.title + module.acf.integral_description + module.acf.short_description + module.acf.version + module.acf.language
            let searchResult = searchableContent.toLowerCase().includes(this.state.searchValue.toLowerCase())
            if (matchingCategories && searchResult) {
                return module
            }
        })
        this.setState({ modules: filteredModules }, () => { this.handlePageClick({ selected: 0 }) });
    }

    handleClearSearchClick = () => {
        this.setState({ searchValue: '' }, () => this.filterModules())
    }

    handlePageClick = data => {
        let selected = data.selected;
        this.setState({ pageNumber: selected }, () => {
            this.paginateModules();
        })
    }

    paginateModules() {
        this.setState({
            paginatedModules: this.state.modules.slice(this.state.pageNumber * this.props.modulesPerPage, (this.state.pageNumber + 1) * this.props.modulesPerPage)
        })
    }

    render() {
        let pageCount = Math.ceil(this.state.modules.length / this.props.modulesPerPage)
        return (
        <>
            <PageTitle title={this.props.t('moduleList.title')} backlink={false} />
            <section className="module-list container">
                {this.overviewContent && (
                    <div className="overview" dangerouslySetInnerHTML={{__html: this.overviewContent.content.rendered}} />
                )}
                <div className="two-col">
                    <div className="module-list__categories">
                        <h3>{this.props.t('moduleList.categories')}</h3>
                        <ul>
                            <Checkbox 
                                checked={this.state.allCategoriesChecked} 
                                label={this.props.t('moduleList.all') + ' (' + this.props.modules.length + ')'}
                                onChange={() => this.selectAllCategories()}
                                name={'all'} 
                            />
                            {this.props.categories.map(category => {
                                if (category.id !== 1) {
                                    return (
                                        <Checkbox 
                                            checked={this.state.checkedCategories.includes(category.id)} 
                                            key={category.id}
                                            label={category.name + ' (' + category.count + ')'}
                                            onChange={() => this.updateSelectedCategories(category.id)}
                                            name={category.name} 
                                        />
                                    )
                                }
                            })}
                        </ul>
                    </div>
                    <div className="module-list__list">
                        <div className="module-list__sort">
                            <Dropdown 
                                onChange={this.sortModules} 
                                options={[
                                    { value: "recent", label: this.props.t('moduleList.sort-added') },
                                    { value: "title-asc", label: this.props.t('moduleList.sort-title-asc') },
                                    { value: "title-desc", label: this.props.t('moduleList.sort-title-desc') }
                                ]}
                            />
                        </div>
                        <div className="search-input">
                            <FontAwesomeIcon icon={faSearch} />
                            <input 
                                type="text"
                                value={this.state.searchValue}
                                onChange={ e => { this.setState({ searchValue: e.target.value }, () => { this.filterModules() }) }}
                                placeholder={this.props.t('moduleList.search')}
                            />
                            { this.state.searchValue.length > 0 && (
                                <div className="search-input__clear" onClick={() => this.handleClearSearchClick()}>&times;</div>
                            )}
                        </div>
                        {this.state.paginatedModules.map(module => {
                            let author = this.props.users.filter(author => module.author === author.id);
                            return <ModuleBlock
                                key={module.id}
                                id={module.id}
                                slug={module.slug}
                                title={module.title.rendered}
                                verified={module.acf.verified}
                                thumbnail={module.acf.thumbnail_image ? {url: module.acf.thumbnail_image.url, alt: module.acf.thumbnail_image.alt} : ''}
                                excerpt={module.acf.short_description}
                                moduleCategories={module.categories}
                                allCategories={this.props.categories}
                                date={module.acf.date_published ? moment(module.acf.date_published, 'DD/MM/YYYY') : moment(module.date)}
                                author={(author && author[0].name) ? author[0].name : null}
                                download={module.acf.file.url}
                                fileType={module.acf.file.type}
                                locale={this.props.locale}
                                urlLocale={this.props.urlLocale}
                                ratings={this.state.ratings}
                                t={this.props.t}
                            />
                        })}
                        { this.state.modules.length < 1 && (<div className="no-results"><h3>{this.props.t('moduleList.no-results')}</h3></div>)}
                        { pageCount > 1 && (
                            <div className="module-list__pagination">
                                <ReactPaginate
                                    previousLabel="&lsaquo;"
                                    nextLabel="&rsaquo;"
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={2}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </section>   
            <Footer t={this.props.t} />
        </>                
        )
    }
}

export default withTranslation()(ModuleList)