import React from 'react';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga'
import moment from 'moment';
import PageTitle from '../partials/PageTitle'
import Gallery from '../partials/Gallery'
import Comments from '../partials/Comments'
import CommentForm from '../partials/CommentForm'
import Footer from '../partials/Footer'
import 'moment/locale/fr';
import 'moment/locale/es';

const Media = ({ media }) => {

    // TODO - fix this
    let str = media.media_type
    let newStr = str.split("{:en}").pop().split('{:}')[0];

    switch (newStr) {
        case 'gallery':    
            if (media.gallery) {
                return (
                    <div className="media media--gallery">
                        <Gallery images={media.gallery} className="gallery" />
                    </div>
                )
            }
            else return null
        case 'video': 
            if (media.video.source == 'youtube' && media.video.youtube) {
                return (
                    <div className="media media--video">
                        <div dangerouslySetInnerHTML={{__html: media.video.youtube }} className="video__youtube" />
                    </div>
                )
            } else if (media.video.source == 'video_upload' && media.video.video_upload) {
                return (
                    <div className="media media--video">
                        <video width="100%" height="height" controls className="video__upload">
                            <source src={ media.video.video_upload.url } type={ media.video.video_upload.mime_type } />
                        </video>
                    </div>
                )
            } else {
                return null
            }
        case 'none':
        default: 
            return null
    }
}

const ModuleDetails = props => {
    const t = props.t
    let moduleDetails = props.modules.find(module => module.slug === props.slug);
    let author = props.users.find(user => user.id === moduleDetails.author)
    let moduleComments = []
    props.comments.forEach(comment => {
        if (comment.post === moduleDetails.id) {
            moduleComments.push(comment)
        }
    })
    
    const onDownloadClick = () => {
        ReactGA.event({
            action: 'Downloads',
            category: moduleDetails.title.rendered,
            label: 'Module Detail page'
        });
    }

    return (
        <>
            <PageTitle 
                title={moduleDetails.title ? moduleDetails.title.rendered : ''} 
                backLink={true} 
                locale={props.locale}
            />
            <section className="module-details container">
                <div className="two-col">
                    <div className="details">
                        <div>
                            { moduleDetails.acf.thumbnail_image && ( <img src={moduleDetails.acf.thumbnail_image.url} alt={moduleDetails.acf.thumbnail_image.alt} className="details__icon" />)}
                            <h3>{moduleDetails.title.rendered} {moduleDetails.acf.verified ? <img src={require('../../img/verified.png')} alt='verified' className="verified" /> : ''}</h3>
                        </div>
                        <p><strong>{t('moduleDetails.author')}:</strong> {author.name}</p>
                        {(props.locale == 'de'
                        ) ? (
                            <p><strong>{t('moduleDetails.published')}:</strong> {moduleDetails.acf.date_published ? moment(moduleDetails.acf.date_published, 'DD/MM/YYYY').format("D.M.Y") : moment(moduleDetails.date).format("D.M.Y")}</p>
                        ) : (
                            <p><strong>{t('moduleDetails.published')}:</strong> {moduleDetails.acf.date_published ? moment(moduleDetails.acf.date_published, 'DD/MM/YYYY').locale(props.locale).format("MMMM Do, YYYY") : moment(moduleDetails.date).locale(props.locale).format("MMMM Do, YYYY")}</p>
                        )}
                        {moduleDetails.acf.version && (<p><strong>{t('moduleDetails.version')}:</strong> {moduleDetails.acf.version}</p>)}
                        {moduleDetails.acf.language && (<p><strong>{t('moduleDetails.language')}:</strong> {moduleDetails.acf.language}</p>)}
                        {moduleDetails.acf.file_type && (<p><strong>{t('moduleDetails.file')}:</strong> {moduleDetails.acf.file_type.label}</p>)}
                        {moduleDetails.acf.file && (
                            <div className="download">
                                <a 
                                    href={moduleDetails.acf.file.url} 
                                    download 
                                    onClick={() => onDownloadClick()}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn" 
                                >
                                    <div>{t('general.download')}</div>
                                </a>
                            </div>
                        )}
                    </div>
                    <Media media={moduleDetails.acf.media} />
                </div>
                <hr/>
                <div dangerouslySetInnerHTML={{__html: moduleDetails.acf.integral_description}} className="module-details__long-desc" />
            </section>
            {moduleComments.length > 0 && (
                <>
                    <div className="container"><hr/></div>
                    <Comments comments={moduleComments} t={props.t} />
                </>
            )}
            <CommentForm moduleId={moduleDetails.id} wpJson={props.wpJson} t={props.t} />
            <Footer t={props.t} />
        </>
    )
}


export default withTranslation()(ModuleDetails)