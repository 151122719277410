import React  from 'react';
import moment from 'moment';

const CommentBlock = ({ avatar, author, content, date, isReply, t }) => {
    let formattedDate = moment(date).format('MM/DD/YYYY h:mma')

    return (
        <div className={'comment' + (isReply ? ' comment--reply' : '')}>
            <div className="comment__author">
                <img src={avatar} alt={author} />
            </div>
            <div className="comment__content">
                <div dangerouslySetInnerHTML={{__html: content }} />
                <span dangerouslySetInnerHTML={{__html: isReply ? t('comments.replied-by', { author: author, date: formattedDate }) : t('comments.commented-by', { author: author, date: formattedDate })}} />
            </div>
        </div>
    )
}

const Comments = ({ comments, t }) => {
    const commentList = comments.map(comment => {
        let commentWithReplies = comments.filter(childComment => {
            return childComment.parent === comment.id
        })
        if(comment.parent === 0) {
            return (
                <li key={comment.id}>
                    <CommentBlock 
                        key={comment.id}
                        avatar={comment.author_avatar_urls['48']}
                        author={comment.author_name}
                        content={comment.content.rendered}
                        date={comment.date}
                        isReply={false}
                        t={t}
                    />
                    {(commentWithReplies.length > 0) && (
                        commentWithReplies.map(reply => 
                            <CommentBlock 
                                key={comment.id}
                                avatar={reply.author_avatar_urls['48']}
                                author={reply.author_name}
                                content={reply.content.rendered}
                                date={reply.date}
                                isReply={true}
                                t={t}
                            />
                        )
                    )}
                </li>
            );
        }
    });

    return (
        <div className="comments">
            <div className="container">
                <h2>{comments.length + ' ' + (comments.length > 1 ? 'Comments' : 'Comment')}</h2>
                <ul>{commentList}</ul>
            </div>
        </div>
    );
}

export default Comments;
